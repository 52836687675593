export const strict = false

export const state = () => ({
  user: null,
  error: '',
  setShowAuthForm: false,
  feedback: false,
  isKids: false,
  isDesktop: false,
  siteLoad: false
})

export const mutations = {
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setShowAuthForm(state, payload) {
    state.setShowAuthForm = payload
    setTimeout(() => {
      state.setShowAuthForm = false
    }, 2000)
  },
  setIsKids(state, payload) {
    state.isKids = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    if (typeof payload === 'string') {
      payload = {
        status: 'info',
        msg: payload
      }
    }
    state.error = payload
  },
  clearError(state) {
    state.error = ''
  },
  setSiteLoad(state, payload) {
    state.siteLoad = payload
  }
}

export const actions = {
  nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) commit('setUser', req.user)
      // console.log('app.device', app.$device)
      // console.log('req.headers', req.headers)
      // const isDesktop = !!req.headers['user-agent'].match(/(iPhone|iPod|iPad|Android|BlackBerry)/)
      // console.log('isDesktop nuxtserverinit', isDesktop, !isDesktop)
      commit('setDevice', app.$device)
      commit('setSiteLoad', true)
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}

export default function({ store, redirect, route}) {
  const user = store.state.user
  console.log('middleware auth | user', user)
  console.log('route.name', route.name)
  // if (route.name !== 'index' && !user) {
  //   redirect(`/signin`)
  // }
  // else if (user && user.group !== 777) {
  //   redirect(`/donate${route.query && route.query.bid ? `?bid=${route.query.bid}` : ''}`)
  // }
  // if (!user) redirect(`/signin?ref=${route.fullPath}`)
}

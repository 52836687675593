<template>
  <div :class="`${!isDesktop ? 'mobile' : '' }`">
    <Mobile v-if="!isDesktop" />

    <Mainmenu />

    <nuxt />

    <BenefitsComponent v-if="showBlocks['benefits'] && (!isKids && !user)" />
    <div v-else data-block-name="benefits" class="lazy-block" style="height: 10px;" />

    <FooterComponent v-if="showBlocks['footer'] && !isKids" />
    <div v-else data-block-name="footer" class="lazy-block" style="height: 10px;" />

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>

    <!-- <div v-if="flyrollCounter" class="flyroll">
      <span class="close">
        {{ flyrollCounter }}
      </span>
      <div class="body">
        <Rsya :payload="{ blockid: 'R-A-1430566-1', page: 1 }" />
      </div>
    </div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import global from '~/mixins/global.js'
Vue.mixin(global)

export default {
  // middleware: 'auth',
  components: {
    // Rsya: () => import('~/components/Rsya.vue'),
    Error: () => import('~/components/Error.vue'),
    Mobile: () => import('~/components/Mobile.vue'),
    Mainmenu: () => import('~/components/Mainmenu.vue'),
    BenefitsComponent: () => import('~/components/BenefitsComponent.vue'),
    FooterComponent: () => import('~/components/FooterComponent.vue')
  },
  watch: {
    fullPath() {
      document.body.style.overflow = ''
      document.documentElement.scrollTo(0, 0)
      this.checkShowVideoblock()
      if (!this.flyrollCounter) this.flyrollCounter = 40
    }
  },
  computed: {
    fullPath() {
      return this.$route.fullPath
    },
    ...mapState(['error', 'isKids', 'user', 'isDesktop'])
  },
  data() {
    return {
      loading: false,
      showMovieAds: false,
      flyrollCounter: 40,
      showBlocks: {
        benefits: false,
        footer: false
      }
    }
  },
  mounted() {
    // await this.isAuth()

    // this.$store.commit('setDevice', this.$device)

    // const clickadilla = document.createElement('script')
    // clickadilla.src = `https://js.wpadmngr.com/static/adManager.js`
    // clickadilla.async = true
    // clickadilla.dataset.admpid = '106843'
    // document.head.appendChild(clickadilla)

    window.addEventListener('scroll', this.lazyLoadImgs)

    if (localStorage.getItem('site_profile')) {
      if (localStorage.getItem('site_profile') === 'kids') {
        this.$store.commit('setIsKids', true)
        document.body.classList.add('kids')
      }
    }

    window.addEventListener('scroll', this.showBlock)

    this.checkShowVideoblock()
    setInterval(() => {
      if (this.flyrollCounter) {
        --this.flyrollCounter
      } else {
        this.flyrollCounter = null
      }
    }, 1000)

    document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    window.addEventListener('resize', () => { 
      document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    })
  },
  methods: {
    checkShowVideoblock() {
      this.showMovieAds = false
      setTimeout(() => {
        this.showMovieAds = true
      }, 1000)
    },
    // async isAuth() {
    //   const { data } = await this.$axios.get(`/api/auth`)
    //   if (data) {
    //     this.$store.commit('setUser', data)
    //   } else {
    //     this.$store.commit('setUser', null)
    //   }
    // },
    showBlock() {
      const doc = document.documentElement.clientHeight
      const list = document.querySelectorAll('.lazy-block')
      list.forEach(block => {
        const visible = doc - block.getBoundingClientRect().top
        const blockName = block.dataset.blockName
        if (visible > 0 && !this.showBlocks[blockName]) {
          this.showBlocks[blockName] = true
        }
      })
    }
  },
}
</script>


<style lang="stylus">
.premium
  height: 100vh
  background-color: #1d1d1d
  .modal
    position: relative
    margin: auto
    top: calc(50% - 180px)
    padding: 20px
    width: 360px
    height: 360px
    background-color: #222 
    border: 2px solid rgba(255, 255, 255, 0.1)
    border-radius: 4px
    text-align: center
    h1
      font-size: 2rem
      text-align: center
      text-transform: uppercase
      margin: 15px 0px 30px
    a
      font-weight: bold
    .checkboxes
      label
        margin: 20px 0
        display: grid
        grid-template-columns: 24px auto
        grid-gap: 10px
        align-items: start
        cursor: pointer
        transition: color 0.2s ease
        &:hover
          color: #ff142a
        input
          width: 24px
          height: 24px
          background-color: #fff
          border: 1px solid #d2d2d2
          border-radius: 4px
        span
          display: inline-block
    .socials
      margin-top: 60px
      text-align: center
      strong
        display: block
        margin-bottom: 20px
        font-size: 1.6rem
        font-weight: 700
      .items
        display: grid
        grid-template-columns: repeat(3, 80px)
        grid-gap: 5px
        justify-content: center
        text-align: center
        .item
          display: grid
          align-items: center
          justify-items: center
          width: 80px
          height: 50px
          background-color: #2d2d2d
          border: 1px solid rgba(255, 255, 255, 0.1)
          border-radius: 4px
          cursor: pointer
          transition: border 0.2s ease
          &:hover
            border: 1px solid #ff142a
          img
            width: 24px
            height: 24px
</style>